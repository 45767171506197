html,
body {
  background-color: #f0f0f0;
  padding: 0;
  margin: 0;
  min-height: 100%;
  height: 100%;
  width: 100%;
  font-size: 12px;
}
ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
a {
  text-decoration: none !important;
}
.masterhead {
  height: 100vh;
  min-height: 500px;
  background-image: url("login-bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.masterhead form {
  background-color: #fff;
  padding: 50px 50px;
  border-radius: 4px;
}
.masterhead form .login-logo {
  margin-bottom: 40px;
}
.masterhead .web-site {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 13px;
  color: #000;
}

#root,
.wrapper {
  height: 100%;
}
.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  /*background: url('../images/loader.svg') 50% 50% no-repeat rgb(0, 0, 0.2);*/
  opacity: 0.9;
}
.maploader {
  position: fixed;
  text-align: center;
  padding: 1em;
  top: 50%;
  left: 60%;
  margin: 0 auto 1em;
  z-index: 9999;
}
.pos-rel {
  position: relative;
}
.flex-left-auto {
  margin-left: auto;
}
h4.page-title {
  margin: 0;
  padding: 10px 0px;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
}

header .navbar-nav {
  padding: 0;
  height: 48px;
}
header .navbar-nav li {
  margin-right: 2px;
}
header .navbar-nav > li > a.nav-link {
  padding: 0 15px !important;
  height: 48px;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.3s ease-in-out;
  font-size: 12px;
  font-weight: 600;
}
header .navbar-nav > li > a.nav-link.active,
header .navbar-nav > li > a.nav-link:hover {
  background-color: #44a3db;
  color: #fff !important;
}
header .navbar-nav > li > a > span {
  width: 100%;
  display: block;
  height: 50%;
}
header .navbar-nav > li > a > span > i {
  font-size: 22px;
  line-height: 1.5;
}
header .navbar-nav > li > a > span:last-child {
  line-height: 20px;
}
header .navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 3rem;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  color: #fff;
  background-color: #44a3db;
  border-color: #44a3db;
  box-shadow: none;
  outline: 0;
  border-radius: 2px;
}
.nav-tabs .nav-link {
  border: 0;
}
article.main-nav {
  padding: 0px 15px;
}

.main-nav .nav-tabs {
  border-bottom: 2px solid #44a3db;
  height: 32px;
}
.main-nav .nav-tabs > .nav-item {
  height: 30px;
  margin-bottom: 0;
}
.main-nav .nav-tabs > .nav-item > a {
  height: 100%;
  background-color: #e9ecef;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  color: #000;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  font-size: 11px;
}
.main-nav .nav-tabs > .nav-item > a.active,
.main-nav .nav-tabs > .nav-item > a:hover {
  background-color: #44a3db;
  color: #fff;
}

/*.accordion > .card .card-header{
	margin-bottom: 0;
}*/
.card {
  border-radius: 0;
  border: 0;
}
.card-header {
  padding: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 0px;
  margin-bottom: 0;
}
.card-body {
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.row.trim-margin {
  margin: 0;
}
form.tg-forms {
}
form.tg-forms input[type="text"],
form.tg-forms input[type="password"],
form.tg-forms input[type="number"],
form.tg-forms input[type="date"],
form.tg-forms select {
  outline: 0;
  padding: 0px 5px;
  height: 26px;
  line-height: 26px;
  background-color: #f2f2f2;
  border: 1px solid #e3e3e3;
  box-shadow: none;
  border-radius: 2px;
  font-size: 13px;
  color: #000;
  border-radius: 1px !important;
}
form.tg-forms .form-group-btns {
  text-align: right;
}
form.tg-forms .form-group-btns > a {
  margin-left: 10px;
}
form.tg-forms label {
  margin-bottom: 2px;
}

.control-custom,
select.control-custom {
  width: 100%;
  height: 28px;
  padding: 1px 10px;
  margin-bottom: 5px;
  background-color: #f0f0f0;
  outline: 0;
  border: 1px solid #d5d5d5;
  box-shadow: none;
  border-radius: 1px !important;
  transition: all 0.3s ease-in-out;
}
.control-custom:focus {
  outline: 0;
  box-shadow: none;
  background-color: #e1e1e1;
  border: 1px solid #b7b6b6;
}
select.control-custom {
}
.label-custom {
  text-align: right;
}
.label-custom > span {
  padding-right: 15px;
  line-height: 30px;
}
.form-icon {
  position: relative;
}
.form-icon > span {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #888;
}

.box-wrap {
  height: calc(100% - 92px);
  padding: 0px 15px;
}
.stay-vertical {
  display: flex;
  width: 100%;
  height: 200px;
}
.stay-vertical > div {
  margin: auto;
  text-align: center;
}

@media (min-width: 992px) {
  .animate {
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}

.loggedin-toggle {
  border: 0;
  background-color: none;
  text-align: right;
  cursor: pointer;
}
.loggedin-toggle > span {
  display: block;
}
.loggedin-toggle > span:nth-child(1) {
  font-size: 13px;
  height: 50%;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 700;
}
.loggedin-toggle > span:nth-child(2) {
  font-size: 11px;
  height: 50%;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 700;
}
.loggedin-toggle > span:nth-child(3) {
  font-size: 13px;
  height: 100%;
}

.loggedin-toggle::after {
  display: none;
}

/*.dashboard{
	height: calc(100% - 80px);
	padding: 0px 15px;
}*/
.dashboard-data {
  height: calc(100% - 15px);
}
.dashboard-data > .row,
.dashboard-data > .row > [class^="col"] {
  height: 100%;
}
.dashboard-data .map {
  display: block;
  width: 100%;
  min-height: 100%;
  height: 100%;
  background-color: #f2efe9;
}
.dashboard-data .tab-search {
  height: 30px;
}
.dashboard-data .tab-content {
  padding: 15px;
  border-radius: 0;
  border: 0;
  height: calc(100% - 300px);
  overflow-y: auto;
  overflow: scroll;
}
.dashboard-data .truck-status {
  height: 100%;
}
.dashboard-data .truck-status .nav-tabs {
  border-bottom: 2px solid #44a3db;
  height: 30px;
}
.dashboard-data .truck-status .nav-tabs > .nav-item {
  height: 28px;
  margin-bottom: 0;
}
.dashboard-data .truck-status .nav-tabs > .nav-item > a {
  height: 100%;
  background-color: #e9ecef;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  color: #000;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  font-size: 12px;
}
.dashboard-data .truck-status .nav-tabs > .nav-item > a.active,
.dashboard-data .truck-status .nav-tabs > .nav-item > a:hover {
  background-color: #44a3db;
  color: #fff;
}

.dashboard-data .trcuk-breadcrump {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  padding: 4px;
  background-color: #f7f7f7;
  border-top: 1px solid #eee;
}
.dashboard-data .trcuk-breadcrump > .row > [class^="col"] {
  padding: 0px 5px;
}
.dashboard-data .trcuk-breadcrump .form-group {
  margin-bottom: 5px;
}

.accordion .card-header {
  padding: 8px;
  background-color: #f0f0f0;
}

.accordion .card-header .form-check .form-check-input,
.accordion .card-header .form-check .form-check-label {
  cursor: pointer;
}
.accordion .card-header .form-check > span > i {
  font-size: 10px;
}
.accordion .card-body {
  background-color: #e8e8e8;
}
.accordion .card-body ul li span:first-child {
  width: 50px;
  font-size: 11px;
  color: #888;
  display: inline-block;
}
.accordion .card-body ul li span:last-child {
  font-size: 12px;
  color: #000;
  display: inline;
}

.ol-control.ol-hidden {
  display: none;
  width: 0;
  height: 0;
}
.ol-zoom {
  position: absolute;
  top: 10px;
}
.ol-zoom-in,
.ol-zoom-out {
  background-color: #44a3db;
  border: 2px solid #44a3db;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  margin-right: 5px;
  color: #fff;
  font-weight: 700;
}

.table-data {
  padding: 15px;
  margin-bottom: 15px;
}
.table-data .nav-tabs {
  height: 30px;
  border-bottom: 2px solid #44a3db !important;
  margin-bottom: 15px;
}
.table-data .nav-item {
  height: 28px;
  border: 0;
  margin: 0;
}
.table-data .nav-item .nav-link {
  height: 100%;
  padding: 2px 15px;
  background-color: #e9ecef;
  line-height: 26px;
  color: #000;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
}
.table-data .nav-item .nav-link.active,
.table-data .nav-item .nav-link:hover {
  background-color: #44a3db;
  color: #fff;
}
.table-data .nav-item .nav-link > i {
  font-size: 20px;
}

table.table > thead > tr > th {
  background-color: #eeeeee;
  vertical-align: middle;
  border: 0;
  font-size: 12px;
  padding: 6px 4px;
}
table.table > tbody > tr > td {
  vertical-align: middle;
  font-size: 13px;
  color: #594c4c;
}

.ul-horizontal > li {
  display: inline-block;
  margin-left: 2px;
}

.modal-lg {
  max-width: 1100px;
}
.proofofdelivery {
  max-width: 400px;
}

.popup-tender {
  padding: 20px;
}
.strip-pad .form-row [class*="col-"] {
  padding: 0px 5px;
}
.strip-pad .form-row [class*="col-"] > .row {
  padding: 0px;
  margin: 0 0 5px;
}
.strip-pad .form-row [class*="col-"] > .row .form-label {
  display: block;
  line-height: 26px;
  text-align: right;
}
.pageLoader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url(loader.svg) center no-repeat #0000001f;
}

.dropdown-menu {
  padding: 0;
  border-radius: 1px;
  border: 0;
}
.dropdown-menu .dropdown-item {
  background-color: #44a3db;
  border: 0;
  color: #000;
  font-size: 11px;
  font-weight: 600;
  padding: 8px 10px;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
}
.dropdown-menu .dropdown-item:hover {
  background-color: #44a3db;
  color: #fff;
}

.tabify > a {
  background-color: #44a3db;
  border: 1px solid #000;
  min-height: 80px;
  border-radius: 2px;
  margin-bottom: 10px;
}
.tabify > a:active,
.tabify > a:hover {
  background-color: #44a3db;
  border: 1px solid #000;
  min-height: 80px;
  border-radius: 2px;
  margin-bottom: 10px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #44a3db;
  border-color: #44a3db;
}

.report-nav {
  margin: 15px 0px;
}
.report-link {
  margin-top: 15px;
  position: relative;
  background-color: #fff;
  display: block;
  border-radius: 2px;
  padding: 10px;
  text-align: right;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}
.report-link:hover {
  text-decoration: none;
}
.report-link > h4 {
  font-size: 12px;
  color: #000;
  text-transform: uppercase;
}
.report-link > h5 {
  color: #000;
}
.report-link > span {
  position: absolute;
  top: -16px;
  left: 15px;
  height: 36px;
  width: 36px;
  background-color: #44a3db;
  text-align: center;
  color: #000;
  border-radius: 2px;
  line-height: 36px;
  font-size: 18px;
  font-weight: 600;
}

/* jerusha update */
.highlight {
  background-color: #eeeeee;
  border: 0;
  padding: 6px 4px;
}

.bulkupload_box {
  border-width: 1px;
  border-style: dashed;
}
.add_plus {
  color: #44a3db;
  font-size: 20px;
}

/* sunitha update */
.nav-item-driver .nav-link-driver.active {
  background-color: rgba(175, 175, 175, 0.2);
  color: #000;
}
.nav-item-driver .nav-link-driver {
  height: 100%;
  padding: 5px 15px;
  background-color: #ece8e2;
  line-height: 26px;
  color: #000;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
}
.driver-search {
  margin-top: 100px;
}
.driver-tab {
  margin-left: 25px;
  background-color: rgba(175, 175, 175, 0.2);
  color: #000;
}
.tt_form {
  float: right;
}

.search-box {
  position: relative;
  bottom: 3px;
}
.search-box-ul {
  position: absolute;
}
.search-box-li {
  position: relative;
  left: 306%;
}
.spinner-border {
  margin-top: 25px;
  color: #44a3db;
}

/* pavithra update */
.deleteicon {
  font-size: 15px;
  padding-left: 50%;
  text-align: right;
  margin-bottom: 10px;
  color: #44a3db;
  float: right;
}

/* diana update */
.doc-color {
  color: #44a3db;
}
.file-drop-area {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 25px;
  border: 1px dashed #e9ecef;
  border-radius: 3px;
  transition: 0.2s;
}
.file-msg {
  font-size: small;
  font-weight: 300;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
}
.ico_circle {
  padding-top: 20px;
}
.table-data .nav-item .tab-color {
  background-color: #bdb8b3;
  color: #fff;
  height: 100%;
  line-height: 26px;
  color: #000;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 3px 6px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: #eee;
  cursor: pointer;
}
.table-data .tab-tab {
  height: 30px;
  border-bottom: 2px solid #b1adaa !important;
  margin-bottom: 15px;
}
.table-data .nav-item .nav-link.active.tab {
  background-color: #afafaf;
  color: #fff;
  font-size: 10px;
  padding: 4px 15px;
  display: inline;
}
.table-data .nav-item .nav-link.tab {
  background-color: #d2cccc;
  color: #fff;
  font-size: 10px;
  padding: 4px 15px;
  display: inline;
  color: #000;
}
.no-gutters .p-20 {
  padding-left: 37px;
  padding-right: 37px;
}

/* shaji update */
span.error,
label.error {
  color: red;
  font-size: 12px !important;
}

.redux-toastr .toastr .close-toastr {
  font-size: 15px;
  height: 55%;
}
/* .redux-toastr .toastr {
	background-color: green;
} */

i[aria-expanded="true"] .icofont-circled-right {
  display: none;
}
i[aria-expanded="false"] .icofont-circled-down {
  display: none;
}

/* Openlayer map */
/* .ol-popup {
    position: absolute;
    background-color: white;
    -webkit-filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
    filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: 12px;
    left: -50px;
    min-width: 280px;
  }
  .ol-popup:after, .ol-popup:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .ol-popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
  }
  .ol-popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
  }
  .ol-popup-closer {
    text-decoration: none;
    position: absolute;
    top: 2px;
    right: 8px;
  }
  .ol-popup-closer:after {
    content: "X";
  }
  .ol-attribution.ol-unselectable{
    display: none;
  } */
tbody + thead {
  display: none;
}
/*.dataTables_filter,.dataTables_paginate 
{
	float: right;
}*/
.dataTable {
  text-align: left;
}

div.dataTables_wrapper div.dataTables_length label,
div.dataTables_wrapper div.dataTables_filter label {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: 400;
  text-align: left;
}

div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
}
div.dataTables_wrapper div.dataTables_filter select,
div.dataTables_wrapper div.dataTables_filter input {
  width: auto;
}
div.dataTables_wrapper div.dataTables_filter input {
  display: inline-block;
  margin-left: 0.5rem;
}

div.dataTables_wrapper div.dataTables_length select,
div.dataTables_wrapper div.dataTables_length input {
  width: auto;
}

div.dataTables_wrapper div.dataTables_length.d-flex.flex-row label {
  margin-top: 1.2rem;
  margin-right: 1rem;
}

div.dataTables_wrapper
  div.dataTables_length.d-flex.flex-row
  .select-wrapper.mdb-select
  span,
div.dataTables_wrapper
  div.dataTables_length.d-flex.flex-row
  .select-wrapper.mdb-select
  .select-dropdown {
  margin-top: 1rem;
}

div.dataTables_wrapper div.dataTables_length label,
div.dataTables_wrapper div.dataTables_filter label {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: 400;
  text-align: left;
}

div.dataTables_wrapper div.dataTables_info,
div.dataTables_wrapper div.dataTables_paginate {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: 400;
}

div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  text-align: right;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-box-pack: end;
}
div.dataTables_wrapper
  div.dataTables_paginate
  ul.pagination
  .page-item.active
  .page-link:focus {
  background-color: #4285f4;
}
div.dataTables_wrapper
  div.dataTables_paginate
  ul.pagination
  .page-item
  .page-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

table.dataTable thead {
  cursor: pointer;
}
table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
  padding-right: 30px;
}
table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none;
}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  position: relative;
  cursor: pointer;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 0.9em;
  display: block;
  opacity: 0.3;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  right: 1em;
  font-family: "Font Awesome\ 5 Free", sans-serif;
  font-size: 1rem;
  font-weight: 900;
  content: "\f0de";
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  right: 16px;
  font-family: "Font Awesome\ 5 Free", sans-serif;
  font-size: 1rem;
  font-weight: 900;
  content: "\f0dd";
}

table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:after {
  opacity: 1;
}
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  opacity: 0;
}

table.dataTable thead th:after {
  right: 1em !important;
}

.form-control-inline {
  display: inline !important;
}
a.btn-icon {
  color: #44a3db !important;
  text-decoration: none;
}
.row-odd {
  padding: 10px 0;
  border: none;

  margin: 0 -11px;
}
.row-even {
  margin: 0 -11px;
  padding: 10px 0;
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  background-color: rgba(0, 0, 0, 0.03);
}
.bg1 {
  background-color: rgba(0, 0, 0, 0.03);
}
a.btn-icon > i.icofont-minus-circle {
  display: block;
}
a.btn-icon > i.icofont-plus-circle {
  display: none;
}
a.collapsed > i.icofont-minus-circle {
  display: none;
}
a.collapsed > i.icofont-plus-circle {
  display: block;
}

.dropdown > a.arrow-less::after {
  display: none;
}

.all-table tbody td {
  vertical-align: middle;
  padding: 5px 0.75rem;
  background-color: #f8f8f8;
  border-bottom: 2px solid #e3e6e8;
  transition: all 0.2s ease-in-out;
  font-size: 25px;
  white-space: nowrap;
}
.all-table thead th {
  background-color: #afafaf;
  color: black;
  padding: 10px 0.75rem;
  font-weight: 1000;
  font-size: 30px;
  border-bottom: 2px solid #e3e6e8;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: rgb(227, 230, 232);
  vertical-align: middle;
  white-space: nowrap;
}
table {
  border-collapse: collapse;
}
table {
  border-collapse: separate;
  white-space: normal;
  line-height: normal;
  font-weight: normal;
  font-size: medium;
  font-style: normal;
  color: -internal-quirk-inherit;
  text-align: start;
  border-spacing: 2px;
  font-variant: normal;
}
table {
  border-collapse: collapse;
}
label.form-check-label {
  position: relative;
  display: inline-block;
  /* padding: 0 0 0 18px; */
  height: 15px;
  line-height: 15px;
  cursor: pointer;
  font-size: 11px;
  font-weight: 500;
}
.form-check-label {
  margin-bottom: 0;
}
.card {
  border-radius: 0px !important;
}
.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.choosecarriertabnone {
  display: none;
}
.choosecarriertabshow {
  display: block;
}

::after,
::before {
  box-sizing: border-box;
}
/* .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
} */
/* li {
    text-align: -webkit-match-parent;
}
ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: block;
}
.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(0, 0, 0, 0.125);
}	  
		   */

.card {
  border-radius: 0px !important;
}

div#loadAssetOrderModalPopup .card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

div#loadAssetOrderModalPopup .card-header {
  font-weight: 600;
  font-size: 14px;
}
div#loadAssetOrderModalPopup .text-center {
  text-align: center !important;
}
div#loadAssetOrderModalPopup .p-2 {
  padding: 0.5rem !important;
}
div#loadAssetOrderModalPopup .card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
div#loadAssetOrderModalPopup {
  box-sizing: border-box;
  max-width: 5000px;
}

.inputwidthtextbox {
  width: 100%;
}

.styleaddclass {
  width: "25%";
  float: "left";
  color: "red";
}
fieldset {
  overflow: hidden;
}

.some-class {
  float: left;
  clear: none;
}

/* label {
			float: left;
			clear: none;
			
			padding: 2px 1em 0 0;
		  } */

/* input[type=radio],
		  input.radio {
			float: left;
			clear: none;
			margin: 0 10px 0 10px;
			margin-top: 6px;
		  } */

.eld-provider {
  margin-right: 10px;
}
#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.table-striped tbody tr:nth-of-type(even) {
  display: none !important;
}
.mapboxgl-popup-content {
  width: 106%;
}
.dashboard-top-menu {
  padding: 7px 0;
}
.dashboard-top-menu button {
  margin: 0 5px;
  text-transform: uppercase;
}
.dashboard-top-menu button:first-child {
  margin-left: 0;
}
.dashboard-top-menu button:last-child {
  margin-right: 0;
}
.dashboard-top-menu button.btn-light {
  background-color: #e9ecef;
}
.menu-item {
  padding: 0 15px;
  margin: 5px;
  user-select: none;
  cursor: pointer;
  border: none;
}

.menu-item-wrapper > button {
  text-transform: uppercase;
  background-color: #e9ecef;
  color: #000;
}

.menu-item-wrapper > button.btn-light1 {
  background-color: #e9ecef;
}

/* .menu-item-wrapper.active {
			background-color: #44a3db;
			border: #44a3db;
		  } */
.menu-item.active {
  border: 1px #44a3db solid;
}

.scroll-menu-arrow {
  padding: 11px;
  cursor: pointer;
}

.scroll-menu-arrow.scroll-menu-arrow--disabled {
  /*opacity: 0; */
  color: #ddd;
}

.scroll-menu-arrow > div {
  font-size: 22px;
}

.modal-90w {
  width: 90%;
  max-width: none !important;
}
th.sortable > span.order {
  width: 9px;
  height: 9px;
  float: right;
}

th.sortable > span.order::before {
  font-family: "Font Awesome\ 5 Free", sans-serif;
  font-size: 1rem;
  font-weight: 900;
  content: "\f0de";
  position: absolute;
  opacity: 0.2;
}
th.sortable > span.order::after {
  font-family: "Font Awesome\ 5 Free", sans-serif;
  font-size: 1rem;
  font-weight: 900;
  content: "\f0dd";
  position: absolute;
  opacity: 0.2;
}
th.sortable > span.react-bootstrap-table-sort-order::before {
  font-family: "Font Awesome\ 5 Free", sans-serif;
  font-size: 1rem;
  font-weight: 900;
  content: "\f0de";
  float: right;
  opacity: 1;
}

th.sortable > span.dropup::before {
  font-family: "Font Awesome\ 5 Free", sans-serif;
  font-size: 1rem;
  font-weight: 900;
  content: "\f0dd" !important;
  float: right;
  opacity: 1;
}
.order {
  float: right;
  margin-left: 10px;
}
span.react-bootstrap-table-sort-order {
  float: right;
  margin-left: 10px;
}
th.sortable > span.dropup > span.caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid\9;
}
.search-label {
  float: right;
}
.react-bootstrap-table-pagination-total {
  padding-left: 15px;
}
/* .mapboxgl-popup {
			top: 40px!important;
		} */

div.modal-map {
  height: 90%;
}
div.modal-map div.modal-content {
  height: 100%;
}
div.modal-map div.modal-content div.modal-body {
  overflow: hidden;
}

div.modal-map div.modal-content div.map-container {
  height: 100%;
}

.accstyleicon {
  position: absolute;
  margin-left: 8rem;
  top: 5px;
  color: #44a3db;
}
div.modal-dialog div.modal-body.content-overflow {
  overflow: auto !important;
}
.icofont-star {
  font-size: 18px;
  background: #44a3db;
}
form.tg-forms textarea {
  background-color: #f2f2f2;
  border: 1px solid #e3e3e3;
}
.staricon {
  font-size: 15px;
  color: #44a3db;
}
.staricon:hover {
  color: #44a3db;
  text-decoration: none;
}

/* .table>thead:first-child>tr:first-child>th {
			border-top: 0;
			padding: 5px;
		} */

button#dropdownMenu2 {
  color: #fff;
  background: #44a3db;
  border: #44a3db;
}
#change-password {
  margin-top: 15px;
  margin-bottom: 0px;
}
th#actionloadpoints {
  padding-left: 30px;
}
.form-group.json-col.level2.rw-datetime-picker.rw-widget {
  width: 141px;
}
select.form-control.json-col.level2 {
  height: 36px;
}
input.form-control.json-col.level2 {
  height: 36px;
}
th#zipcodeloadpoints {
  padding-left: 39px;
}
th#citynameloadpoints {
  padding-left: 64px;
}
th#statenameloadpoints {
  padding-left: 58px;
}
th#streetnameloadpoints {
  padding-left: 25px;
}
th#instructionloadpoints {
  padding-left: 36px;
}
th#latesttimeloadpoints {
  padding-left: 30px;
}
th#latestimeloadpoints {
  padding-left: 31px;
}
.btn-secondary {
  color: #fff;
  background-color: #44a3db !important;
  border-color: #44a3db !important;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #44a3db;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #44a3db;
  border-color: #44a3db;
}
.page-link:hover {
  z-index: 2;
  color: #44a3db;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

a {
  color: #0e0e0fc4;
  text-decoration: none;
  /* background-color: #44a3db; */
}
.dropdown-menu .dropdown-item {
  background-color: #e9ecef;
  border: 0;
  color: #000;
  font-size: 11px;
  font-weight: 600;
  padding: 8px 10px;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
}

#lanehistoryclass {
  font-size: 21px;
  color: #44a3db;
  background-color: transparent;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #44a3db;
  border-color: #44a3db;
}
@media screen and (min-width: 1440px) and (max-width: 2560px) {
  .table-responsive {
    display: table;
  }
}

@media screen and (max-width: 567px) {
  .strategy-table > thead > tr > th {
    position: relative;
  }
}

@media screen and (max-width: 567px) {
  .nav-tabs {
    margin-top: 1rem;
    border-bottom: 0px solid !important;
  }
}

@media screen and (max-width: 567px) {
  .tab-content {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 567px) {
  .no-gutters {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 567px) {
  #search-bar-0 {
    margin-top: 2rem;
  }
}

i.icofont-video {
  font-size: large;
}

div#map-container {
  width: 100%;
  height: 93.6%;
  display: block;
  position: relative;
}
/* a.btn_carrier_move.btn.btn-sm.btn-primary {
			width: 82px !important;
		} */
/* thead {
			text-align: center;
		} */

.table td {
  /* padding: 0.20rem !important;  */
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.requiredfield {
  border-left: 3px solid #44a3db !important;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #44a3db !important;
  border-color: #44a3db !important;
}

div#stopped_stats,
div#idle_stats,
div#active_stats {
  height: 300px;
  /* overflow-x: hidden;
			overflow-x: auto; */
}

::-webkit-scrollbar {
  width: 1px;
}

/* ::-webkit-scrollbar-track {
			-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
			border-radius: 10px;
		}
		 
		::-webkit-scrollbar-thumb {
			border-radius: 10px;
			-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
		} */

.loader {
  border: 16px solid #f3f3f3;
  /* border-radius: 50%; */
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.react-confirm-alert-body {
  font-family: "sans-serif";
  font-weight: 100;
  font-size: 1rem;
  width: 550px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #44a3db;
}
.react-confirm-alert-button-group > button {
  outline: none;
  background: #44a3db;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

/* .form-group.drivercityselectbox {
			display: none;
		}

		.form-group.cityclassinput {
			display: block;
		} */

.addcontactcontent {
  width: 550px !important;
}

ul#myTab {
  width: auto;
}
#searchicondashboard {
  width: auto;
}
.truck-status {
  padding: 7px;
}

/* .accordion .card-header .form-check > span{
			color: red;
		} */

#map_parent .icofont-circled-left,
#map_parent .icofont-circled-right {
  font-size: 22px;
}

#map_parent .horizontal-menu .menu-item-wrapper .menu-item {
  background-color: #e9ecef;
  color: #000;
}

#map_parent .horizontal-menu .menu-item-wrapper .menu-item.active {
  color: #fff;
}
.bg-color-1 {
  background-color: rgb(79, 129, 189);
  float: left;
  width: 100%;
  padding: 10px;
}
.bg-color-1 h5 {
  margin-bottom: 0px;
  color: #fff;
}
.eld_data {
  float: left;
  width: 100%;
}
.form-control[readonly] {
  background-color: unset;
}
.checkbox-custom,
.checkbox-custom-label,
.radio-custom,
.radio-custom-label {
  display: block;
  vertical-align: middle;
  font-size: 22px;
  cursor: pointer;
  padding-left: 45px;
  word-break: break-word;
}
.checkbox-custom-label,
.radio-custom-label {
  position: relative;
}
.checkbox-custom + .checkbox-custom-label:before,
.radio-custom + .radio-custom-label:before {
  content: "";
  background: #fff;
  border: 2px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  width: 25px;
  height: 25px;
  padding: 2px;
  margin-left: 10px;
  text-align: center;
  position: absolute;
  left: 0px;
  top: 13px;
}
.checkbox-custom:checked + .checkbox-custom-label:before {
  background: rgb(79, 129, 189);
  box-shadow: inset 0px 0px 0px 4px #fff;
}
.radio-custom + .radio-custom-label:before {
  border-radius: 50%;
}
.radio-custom:checked + .radio-custom-label:before {
  background: rgb(79, 129, 189);
  box-shadow: inset 0px 0px 0px 4px #fff;
}
.radio-custom-label img {
  max-width: 100%;
  height: 50px;
  width: 80%;
}
.custom-textbox {
  width: 80%;
  display: inline-block;
  height: 50px !important;
}
.checkbox-custom-label img {
  max-width: 100%;
  height: 50px;
  width: 50px;
}
.radio-custom-label img {
  max-width: 100%;
  height: 50px;
  width: 50%;
}
.checkbox-custom-label:focus,
input.checkbox-custom {
  border: 0px;
  box-shadow: unset;
  outline: 0;
}
.font-size-1 {
  font-size: 18px;
  font-weight: normal;
  line-height: 30px;
}
hr {
  border-top: 3px solid rgba(0, 0, 0, 0.1) !important;
}
.eld-class {
  padding-left: 2rem;
  padding-right: 5rem;
}
.labl {
  font-weight: 600;
}
.carrier {
  width: 100%;
  display: flex;
  align-items: center;
}
.onboard-title {
  margin-bottom: 0px !important;
}
.nav-border {
  border-bottom: 3px solid lightgray;
}
.p-t-5 {
  padding-top: 15px;
}
/* .onboard-class{
			height: 100%;
		} */
::-webkit-scrollbar {
  width: unset;
}
.pos-rel {
  position: relative;
  float: left;
  width: 100%;
}
.pos-abs {
  position: absolute;
  top: 10px;
  right: 10px;
}
#namanyay-search-box {
  background: #eee;
  padding: 10px;
  border-radius: 5px 0 0 5px;
  -moz-border-radius: 5px 0 0 5px;
  -webkit-border-radius: 5px 0 0 5px;
  -o-border-radius: 5px 0 0 5px;
  border: 0 none;
  width: 200px;
}
#namanyay-search-btn {
  background: #0099ff;
  color: white;
  padding: 12px 20px;
  border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  -webkit-border-radius: 0 5px 5px 0;
  -o-border-radius: 0 5px 5px 0;
  border: 0 none;
  font-weight: bold;
}
.ti-search:before {
  content: "\e610";
}
.border-1 {
  border: 1px solid #ddd;
  border-radius: 5px;
}
.radiopopup {
  display: none;
}
.footer {
  padding: 50px 0 !important;
  background: #000;
}
.footer .footer-lbl {
  color: #fff;
  margin-bottom: 15px;
}
.footer .footer-adress,
.footer .footer-phones,
.footer .footer-email {
  color: #fff;
  font-size: 14px;
  line-height: 1.5;
}
.header-section {
  background: #fff;
  padding: 5px 0;
  border-bottom: 2px solid #e8e8e8;
}
.carrier-board {
  margin-bottom: 0px;
  padding-bottom: 20px;
}
.carrier-board .form-control {
  height: 35px !important;
}
.truck-font {
  font-size: 20px;
  margin-bottom: 10px;
}
.board-font {
  margin-bottom: 20px;
  font-size: 16px;
}
.eld-height .border-1 {
  height: 85px;
}
.d-table {
  top: 15rem;
}
.mt-10 {
  margin-top: 10px;
}
.thank-page {
  height: 100% !important;
  background-color: #f0f0f0;
}
.thank-text {
  font-size: 14px !important;
}
.thank-cred {
  font-size: 20px !important;
  margin-top: 10px;
}
a#optinoptoutpage {
  margin-bottom: -26px;
}
#user_nav .homelabel {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}
li.searchicon {
  margin-top: -6px;
}
button.searchbtn {
  margin-top: 20px;
}
.shipperLaneTable {
  text-align: center;
}

.all-table thead th {
  background-color: #afafaf;
  color: #000;
  padding: 10px 0.75rem;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 12px;
  border-bottom: 5px solid #000;
  vertical-align: middle;
  white-space: nowrap;
}

.rate-table.all-table thead tr th {
  border-bottom: 3px solid #ddd;
  border-left: 3px solid #ddd;
}

.shipperAddmodel {
  max-width: 1350px;
}
span.nav-item.homelabel {
  font-style: italic;
  font-size: 20px;
}
.InvalidKeyhomelabel {
  font-style: bold;
  font-size: 20px;
}
.mx-auto.frgtpwd {
  width: 354px;
  margin-top: 10rem;
}
.columnclass {
  background-color: white;
  padding: 1rem;
}

.form-group.btnresetpwd {
  float: right;
}

input.form-control.json-col.remarks {
  width: 71px;
}

ul#brokeravailableloads {
  margin-top: -20px;
}

#mapiconClass {
  font-size: 25px;
  color: #44a3db;
  background-color: transparent;
}

table.table > tbody > tr > td {
  padding-left: 0.4rem;
}

.adminuser {
  margin-top: -5px;
}
/* select#carrierNameChange {
			width: 116px;
		} */

.scroll-menu-arrow.scroll-menu-arrow--disabled {
  color: #ddd;
}
* {
  scrollbar-width: thin;
  scrollbar-color: #c6c6c6 #e9ecef;
}
*::-webkit-scrollbar {
  width: 8px;
}
*::-webkit-scrollbar-track {
  background: #e9ecef;
}
*::-webkit-scrollbar-thumb {
  width: 8px;
  background-color: #c6c6c6;
  border-radius: 10px;
}

.ff {
  width: 100%;
  height: calc(100% - 42px);
  position: relative;
}
.leaflet-container {
  width: 100vw;
  height: 100vh;
}

.marker-cluster-small {
  background-color: rgba(181, 226, 140, 0.6);
}
.marker-cluster-small div {
  background-color: rgba(110, 204, 57, 0.6);
}

.marker-cluster-medium {
  background-color: rgba(241, 211, 87, 0.6);
}
.marker-cluster-medium div {
  background-color: rgba(240, 194, 12, 0.6);
}

.marker-cluster-large {
  background-color: rgba(253, 156, 115, 0.6);
}
.marker-cluster-large div {
  background-color: rgba(241, 128, 23, 0.6);
}

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
  background-color: rgb(181, 226, 140);
}
.leaflet-oldie .marker-cluster-small div {
  background-color: rgb(110, 204, 57);
}

.leaflet-oldie .marker-cluster-medium {
  background-color: rgb(241, 211, 87);
}
.leaflet-oldie .marker-cluster-medium div {
  background-color: rgb(240, 194, 12);
}

.leaflet-oldie .marker-cluster-large {
  background-color: rgb(253, 156, 115);
}
.leaflet-oldie .marker-cluster-large div {
  background-color: rgb(241, 128, 23);
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}
.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;

  text-align: center;
  border-radius: 15px;
  font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}
.marker-cluster span {
  line-height: 30px;
}
.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow {
  -webkit-transition: -webkit-transform 0.3s ease-out, opacity 0.3s ease-in;
  -moz-transition: -moz-transform 0.3s ease-out, opacity 0.3s ease-in;
  -o-transition: -o-transform 0.3s ease-out, opacity 0.3s ease-in;
  transition: transform 0.3s ease-out, opacity 0.3s ease-in;
}

.leaflet-cluster-spider-leg {
  /* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
  -webkit-transition: -webkit-stroke-dashoffset 0.3s ease-out,
    -webkit-stroke-opacity 0.3s ease-in;
  -moz-transition: -moz-stroke-dashoffset 0.3s ease-out,
    -moz-stroke-opacity 0.3s ease-in;
  -o-transition: -o-stroke-dashoffset 0.3s ease-out,
    -o-stroke-opacity 0.3s ease-in;
  transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in;
}

.red_marker {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #f00;
  color: white;
  text-align: center;
  font-size: 20px;
  line-height: 40px;
  margin-top: -20px;
  margin-left: -20px;
}
.green_marker {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #008000;
  color: white;
  text-align: center;
  font-size: 20px;
  line-height: 40px;
  margin-top: -20px;
  margin-left: -20px;
}
.orange_marker {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #fdc800;
  color: white;
  text-align: center;
  font-size: 20px;
  line-height: 40px;
  margin-top: -20px;
  margin-left: -20px;
}

.cPopup > ul > li > span:first-child {
  display: inline-block;
  width: 56px;
  text-align: right;
  font-size: 12px;
  color: #959595;
}
.cPopup > ul > li > span:last-child {
  margin-left: 10px;
  display: inline-block;
  text-align: left;
  font-size: 12px;
  color: #000;
}
.leaflet-popup.red-border {
}
.leaflet-popup.green-border {
}
.leaflet-popup.orange-border {
}
.leaflet-popup-content-wrapper {
  border-radius: 4px;
}

.toggle_radio label {
  display: -webkit-box;
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.375em;
}
.toggle_radio label input {
  position: absolute;
  left: -9999px;
}
.toggle_radio label input:checked + span {
  background-color: #d6d6e5;
}
.toggle_radio label input:checked + span:before {
  box-shadow: inset 0 0 0 0.4375em #00005c;
}
.toggle_radio label span {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 0.375em 0.75em 0.375em 0.375em;
  border-radius: 99em;
  -webkit-transition: 0.25s ease;
  transition: 0.25s ease;
}
.toggle_radio label span {
  background-color: #fff;
}
.toggle_radio label span:hover {
  background-color: #44a3db;
}
.toggle_radio label span:before {
  display: -webkit-box;
  display: flex;
  flex-shrink: 0;
  content: "";
  background-color: #fff;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  margin-right: 0.375em;
  -webkit-transition: 0.25s ease;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 0.125em #00005c;
}

.type-buttons {
  position: absolute;
  top: 0;
  left: 40px;
  z-index: 999999;
  padding: 10px;
}
.dashboard-top-menu.dummy {
  height: 44px !important;
}
.custom_label {
  position: absolute;
  top: 0 !important;
  right: 0 !important;
}
#map > div.leaflet-container {
  height: 100%;
  width: 100%;
}
div.leaflet-marker-icon {
  border: 0;
}
.marker-cluster-active {
  background: #00e64d;
  border: 2px solid #000;
  border-radius: 50%;
  color: #ededed;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px;
}
.marker-cluster-stopped {
  background: red;
  border: 2px solid #000;
  border-radius: 50%;
  color: #ededed;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px;
}
.marker-cluster-ideal {
  background: yellow;
  border: 2px solid #000;
  border-radius: 50%;
  color: #ededed;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px;
}
.tableIcon {
  font-size: 18px !important;
}

ul.dropdown-menu {
  z-index: 99999;
}
li.dropdown-submenu {
  position: relative;
}
li.dropdown-submenu > .dropdown-menu {
  position: absolute;
  left: 0;
  top: 46px;
  background-color: #e9ecef;
  min-width: 150px;
  width: auto;
}
li.dropdown-submenu > .dropdown-menu:after,
li.dropdown-submenu > .dropdown-menu:before {
  bottom: 100%;
  left: 20%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
li.dropdown-submenu > .dropdown-menu:after {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #e9ecef;
  border-width: 10px;
  margin-left: -10px;
}
li.dropdown-submenu > .dropdown-menu:before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: #e9ecef;
  border-width: 10px;
  margin-left: -10px;
}

li.dropdown-submenu > .dropdown-menu > li,
li.dropdown-submenu > .dropdown-menu > li > a {
  display: block;
  width: 100%;
  font-size: 13px;
  color: #000;
}
li.dropdown-submenu > .dropdown-menu > li > a {
  margin: 5px 0px;
  padding: 0px 15px;
  line-height: 30px;
  transition: all 0.3s ease-in-out;
}
li.dropdown-submenu > .dropdown-menu > li > a:hover {
  background-color: #44a3db;
  color: #fff;
}

.dropdown-menu > li {
  position: relative;
  width: 100%;
}
.dropdown-menu a.open-third {
  position: relative;
}
.dropdown-menu a.open-third:before {
  position: absolute;
  top: 0;
  right: 10px;
  font-family: "IcoFont", sans-serif;
  content: "\eaa0";
}

.dropdown-third {
  position: absolute;
  left: 148px !important;
  top: 0px;
  display: none;
  background-color: #e9ecef;
  min-width: 200px;
  width: auto;
  z-index: 99999;
}
.dropdown-third > a {
  display: block;
  width: 100%;
  line-height: 30px;
  font-size: 12px;
  color: #000;
  font-size: 600;
  transition: all 0.3s ease-in-out;
  padding: 0px 10px;
}
.dropdown-third > a:hover {
  color: #fff;
  background-color: #44a3db;
}
.dropdown-menu li:hover > .dropdown-third {
  display: block !important;
}

.leaflet-tooltip-pane > div.pop-c {
  background-color: #fff;
  transition: all 0.4s ease-in-out;
  border: 1px solid #fff;
  padding: 8px;
  min-width: 240px;
}
.leaflet-tooltip-pane > div.pop-c ul {
  position: relative;
}
.leaflet-tooltip-pane > div.pop-c ul > div {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 10px;
  color: #ddd;
}
.leaflet-tooltip-pane > div.pop-c ul > div.pickup {
  color: red;
}
.leaflet-tooltip-pane > div.pop-c ul > div.drop {
  color: green;
}
.leaflet-tooltip-pane > div.pop-c ul > div:before {
  position: absolute;
  content: "";
  top: 4px;
  right: -12px;
  width: 10px;
  height: 10px;
  border: 2px solid #ddd;
  border-radius: 50%;
}
.leaflet-tooltip-pane > div.pop-c ul > div.pickup:before {
  border: 2px solid red;
}
.leaflet-tooltip-pane > div.pop-c ul > div.drop:before {
  border: 2px solid green;
}
.leaflet-tooltip-pane > div.pop-c li {
  font-size: 11px;
  color: #000;
}
.leaflet-tooltip-pane > div.pop-c li > span {
  display: inline-block;
  width: 40px;
  font-size: 10px;
  text-align: right;
  color: #959595;
  margin-right: 10px;
}

.edit-buttons {
  display: block;
  position: absolute;
  top: 0;
  left: 40px;
  z-index: 999999;
  padding: 10px;
}
.edit-buttons > label {
  display: block;
  width: 80px;
  padding: 4px 12px;
  text-align: center;
  background-color: #44a3db;
  margin-bottom: 5px;
  border-radius: 24px;
  color: #000;
  font-weight: 600;
  cursor: pointer;
}
.cobpdf {
  font-size: 20px;
  color: #ff0000;
  margin-top: -3px;
}

.icofont-action-buttons {
  font-size: 20px;
  color: #44a3db;
  margin-top: -3px;
}

.instagram {
  background-color: #3f729b;
  color: #fff !important;
}
.linkedin {
  background-color: #0e76a8;
  color: #fff !important;
}
.twitter {
  background-color: #00acee;
  color: #fff !important;
}
.facebook {
  background-color: #3b5998;
  color: #fff !important;
}
table.myTable {
  width: 100% !important;
  table-layout: fixed !important;
  overflow-wrap: break-word !important;
}

table.myTable > tbody > tr > td {
  vertical-align: middle;
  font-size: 13px;
  color: #594c4c;
  width: 20%;
  word-wrap: break-word;
}
table.myTable > tbody > tr > td.tableCellBold {
  width: 10% !important;
  font-weight: 600 !important;
  background-color: #f5f5f5;
}
.text-word-wraps {
  word-break: break-word;
}

.bottom-left {
  position: absolute;
  bottom: 0px;
  left: 12px;
  margin-bottom: 1rem;
  border: 1px solid #dee2e6;
  z-index: 99;
  width: 470px;
  display: none;
}

/* 
	google map */

.gm-style .gm-style-iw-t {
  position: absolute;
  width: 100%;
  bottom: 28px !important;
}

form.breadcrumbpopup .trim-margin > h4 {
  display: inline-block;
  margin: 5px 10px;
  width: 85%;
}

form.breadcrumbpopup .trim-margin > a {
  display: inline-block;
  width: 10%;
  margin-top: 10px;
}
.accordion .card .card-header a {
  font-weight: 500;
}
.accordion .card .card-header a:hover {
  color: #0e0e0fc4 !important;
}
